<template>
	<div>
		<CRow>
			<CCol lg="12" class="d-flex">
				<h4 class="mb-4 general-info">
					General info
				</h4>

				<div class="banner-id">
					Banner ID: <strong>{{ bannerId }}</strong>
				</div>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="9">
				<CInput
					v-model.trim="$v.nameValue.$model"
					:is-valid="!$v.nameValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					:label="nameLabel"
					type="text"
					data-test-id="input-name"
				/>
			</CCol>
			<CCol md="3">
				<CInput
					v-model.trim="$v.priorityValue.$model"
					:is-valid="!$v.priorityValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Priority*"
					type="number"
					data-test-id="input-priority"
				/>
			</CCol>
		</CRow>
		<template v-if="showWidget">
			<CRow>
				<CCol md="9">
					<label class="mb-4">
						Link to
					</label>
				</CCol>
				<CCol md="3" class="text-right mb-3">
					<CButton
						color="secondary"
						@click="$refs['modal-widget'].open()"
					>
						<CIcon class="mr-2" name="cil-plus" /> Add
					</CButton>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<BaseNoItemBanner
						v-if="!widgetsValue.length"
						class="mb-4"
						text="There is no widget linked."
					/>
					<ul v-else class="widget-list">
						<li
							v-for="item in widgets"
							:key="item.id"
							class="list-item typo-body-2"
						>
							{{ item.name }}

							<CButton
								class="transparent p-0"
								data-test-id="btn-remove-widget"
								@click="removeWidget(item.id)"
							>
								<CIcon
									class="icon-remove-widget"
									name="cil-x"
								/>
							</CButton>
						</li>
					</ul>
				</CCol>
			</CRow>

			<ModalWidget
				ref="modal-widget"
				:list="widgetsValue"
				:widget-type="widgetType"
				@onConfirm="handleWidget"
			/>
		</template>

		<CRow class="mb-5">
			<CCol xl="12" class="d-flex align-items-center">
				<label class="toggle-label" for="display-status">Status</label>
				<CSwitch
					id="display-status"
					:checked="status"
					variant="3d"
					size="sm"
					class="switch-custom toggle-status"
					color="success"
					@update:checked="handleUpdateStatus"
				/>
				<span>{{ statusText }}</span>
			</CCol>
		</CRow>

		<hr>
	</div>
</template>

<script>
import ModalWidget from '@/components/ModalWidget.vue';

import { required } from 'vuelidate/lib/validators';
import { STATUS as mappingStatus } from '../mapping/banner';

export default {
	name: 'BannerGeneral',

	components: {
		ModalWidget,
	},

	validations() {
		return {
			nameValue: {
				required,
			},
			priorityValue: {
				required,
			},
		};
	},

	props: {
		id: {
			type: Number,
			default: 0,
		},
		name: {
			type: String,
			default: '',
		},
		nameLabel: {
			type: String,
			default: '',
		},
		widgets: {
			type: Array,
			default: () => [],
		},
		widgetType: {
			type: String,
			default: null,
		},
		priority: {
			type: [String, Number],
			default: 0,
		},
		status: {
			type: Boolean,
			default: false,
		},
		showWidget: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		bannerId() {
			return this.id ? this.id : this.$t('page.banner.create.generateId');
		},

		nameValue: {
			get() {
				return this.name;
			},
			set(value) {
				this.$emit('update:name', value);
			},
		},

		priorityValue: {
			get() {
				return this.priority;
			},
			set(value) {
				this.$emit('update:priority', value);
			},
		},

		widgetsValue: {
			get() {
				return this.widgets;
			},
			set(value) {
				this.$emit('update:widgets', value);
			},
		},

		statusText() {
			const text = mappingStatus[this.status] || '';
			return this.$t(text);
		},
	},

	methods: {
		// TODO: get it from action after integrated
		clearError() {},
		handleWidget(widgets) {
			this.widgetsValue = widgets;
		},
		handleUpdateStatus(value) {
			this.$emit('update:status', value);

			this.clearError();
		},
		removeWidget(id) {
			this.widgetsValue = this.widgetsValue.filter((widget) => widget.id !== id);
		},
	},
};
</script>

<style lang="scss" scoped>
	.general-info {
		flex: 1;
	}

	.banner-id {
		font-size: rem(12);
		color: $color-black-45;
	}

	.widget-list {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16) rem(8);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;
		list-style: none;

		.list-item {
			border-radius: rem(4);
			background-color: $color-gray-300;
			color: $color-black-90;
			display: inline-block;
			padding: 0 rem(6);
			margin-right: rem(12);
			margin-bottom: rem(12);
		}

		.icon-remove-widget {
			color: $color-gray-700;
			margin-left: rem(4);
			width: rem(14);
			position: relative;
			top: -1px;
		}
	}

	.toggle {
		&-label {
			width: rem(54);
			margin: 0 rem(24) 0 0;
		}

		&-status {
			margin-right: rem(12);
		}
	}
</style>
